import React, {useEffect, useState} from "react"
import JournalCardList from "./JournalCardList";
import {getConnected} from "../../util/helper";
import {useTranslation} from "gatsby-plugin-react-i18next";
import {deleteArticle, getArticles} from "../../queries";
import {initialArArticle} from "../../util/type/types";
import {DeleteModal} from "../modal/deleteModal";
import {AddJournal} from "../modal/addJournal";

const ArchivedJournalContent = ({}: any) => {

    let connected = getConnected()
    const [user, setUser] = useState(connected || null)
    const [selected, setSelected] = useState<any>(null)
    const [articles, setArticles] = useState<any>([])
    const {t, i18n} = useTranslation()
    useEffect(() => {
        getArticles(setArticles, i18n.language, true)

        return () => {
            setArticles([])
            setSelected(null)
        };
    }, []);
    return (
        <>
            {/*<JournalHeader user={user} setSelected={setSelected}/>*/}
            <div className="container">
                <div className="row">
                    <JournalCardList
                        user={user}
                        selected={selected}
                        setSelected={setSelected}
                        articles={articles}/>
                </div>
            </div>

            <AddJournal
                initData={selected ? {
                    id: selected?.id,
                    title: selected?.title,
                    summary: selected?.summary,
                    content: selected?.content,
                    archived: selected?.archived,
                    authors: selected?.authors.split('__'),
                    keywords: selected?.keywords.split('__'),
                    authorId: selected?.authorId,
                    lang: selected?.lang,
                    docFileName: selected?.docFileName,
                } : initialArArticle}
                name={t('New Article')}
                selectId={0}
            />
            <DeleteModal name={t('Article of journal')} id={selected?.id} deleteFunction={(id: number) => {
                deleteArticle(id)
            }}/>
        </>
    )
}

export default ArchivedJournalContent;
